import HeroWave from "./HeroWave";

import mainLogo from "../Assets/main-logo.png";

const Header = () => {
  return (
    <nav className="relative bg-hispanaBlue">
      <div className="absolute inset-x-0 bottom-0">
        <HeroWave />
      </div>
      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 max-h-[200px]">
        <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl text-center">
          <h2 className="-mt-6 font-sans text-3xl font-bold tracking-tight text-white sm:leading-none">
            Men&uacute; Cafeter&iacute;a <br /> Hispana Huauchinango
          </h2>
          <div className="flex items-center justify-center mx-auto">
            <img
              src={mainLogo}
              alt="Cafeter&iacute;a Hispana"
              className="w-32 mt-6"
            />
          </div>
        </div>
      </section>
    </nav>
  );
};

export default Header;
