import React from "react";

import Container from "./Container";

const ProductoDescription = ({ variante, precio }) => {
  return (
    <div className="w-full flex justify-between items-center">
      <p className="mt-1 text-xs font-medium text-gray-900 w-[120px]">
        {variante}
      </p>
      <p className="mt-1 text-md font-medium text-gray-900">${precio}.00</p>
    </div>
  );
};

const ProductsCard = ({ imagen, platillo, tipos }) => {
  return (
    <div className="group">
      <div className="aspect-w-1 aspect-h-1 w-full h-[10rem] overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
        <img
          src={imagen}
          alt="Item del menu"
          className="h-full w-full object-cover object-center group-hover:opacity-75"
        />
      </div>
      <h3 className="mt-4 text-lg text-gray-600 border-b-2 border-gray-100">
        {platillo}
      </h3>
      {tipos.map((item, idx) => {
        return (
          <ProductoDescription
            variante={item.variante}
            precio={item.precio}
            key={idx}
          />
        );
      })}
    </div>
  );
};

const Productos = ({ currentMenu }) => {
  return (
    <Container title="Men&uacute;">
      <div className="grid grid-cols-2 gap-y-10 gap-x-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
        {currentMenu.map((item, idx) => {
          return (
            <ProductsCard
              imagen={item.imagen}
              platillo={item.platillo}
              tipos={item.tipos}
              key={idx}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default Productos;
