export const menu = [
  {
    platillo: "Chilaquiles",
    tipos: [
      {
        variante: "Verdes Solos",
        precio: 30
      },
      {
        variante: "Verdes Con Huevo Estrellado",
        precio: 35
      },
      {
        variante: "Verdes Con Pollo",
        precio: 40
      },
      {
        variante: "Verdes Con Milanesa de Pollo o Cecina ",
        precio: 60
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/chilaquiles.png?updatedAt=1693950152169"
  },
  {
    platillo: "Huevos (Incluye frijoles)",
    tipos: [
      { variante: "Al Gusto", precio: 40 },
      {
        variante: "A la mexicana / Al albañil / Revueltos con jamón",
        precio: 35
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/huevo.png?updatedAt=1693950153666"
  },
  {
    platillo: "Torta Preparada (1)",
    tipos: [
      {
        variante: "Pollo desmenuzado",
        precio: 40
      },
      {
        variante: "Milanesa de Pollo",
        precio: 40
      },
      {
        variante: "Jamón o Salchicha",
        precio: 35
      },
      {
        variante: "Huevo con Salchicha",
        precio: 40
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/torta.png?updatedAt=1693950155206"
  },
  {
    platillo: "Galletas",
    tipos: [
      {
        variante: "Gamesa o Marinela",
        precio: 18
      },
      {
        variante: "Barritas",
        precio: 10
      },
      {
        variante: "Oreo",
        precio: 18
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/galletas.png?updatedAt=1693950152172"
  },
  {
    platillo: "Sincronizada",
    tipos: [
      {
        variante: "Queso y Jamón",
        precio: 20
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/sincronizadas.jpg?updatedAt=1693950153912"
  },
  {
    platillo: "Orden de Enchiladas (Orden de 4) Rojas o Verdes",
    tipos: [
      {
        variante: "Con Pollo y Salsa",
        precio: 40
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/enchiladas.png?updatedAt=1693950152185"
  },
  {
    platillo: "Tacos de guisados (Orden de 3)",
    tipos: [{ variante: "Con arroz o frijoles", precio: 50 }],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/guisadosTacos.jpg?updatedAt=1693950153692"
  },
  {
    platillo: "Tacos árabes u orientales",
    tipos: [
      { variante: "2 Tacos", precio: 45 },
      { variante: "1 Taco", precio: 25 }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/t-arabes.png?updatedAt=1693950154131"
  },
  {
    platillo: "Papas a la Francesa",
    tipos: [
      {
        variante: "De Tira",
        precio: 35
      },
      {
        variante: "De Gajo",
        precio: 35
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/french.jpg?updatedAt=1693950152170"
  },
  {
    platillo: "Sandwich (1) Pan Blanco",
    tipos: [
      {
        variante: "de Jamón o Pollo",
        precio: 35
      },
      {
        variante: "de Salchicha",
        precio: 30
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/sandwich.png?updatedAt=1693950153924"
  },
  {
    platillo: "Postres",
    tipos: [
      {
        variante: "Rebanada de Carlota",
        precio: 25
      },
      {
        variante: "Gelatina de Leche/Agua",
        precio: 15
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/postre.png?updatedAt=1693950153776"
  },
  {
    platillo: "Orden de Nugetts de Pollo",
    tipos: [
      {
        variante: "Nuggets de Pollo",
        precio: 40
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/nuggets.png?updatedAt=1693950153741"
  },
  {
    platillo: "Sopa",
    tipos: [{ variante: "Sopa Maruchan", precio: 25 }],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/maruchan.png?updatedAt=1693950153874"
  },
  {
    platillo: "Licuados",
    tipos: [
      {
        variante: "Choco Milk",
        precio: 30
      },
      {
        variante: "Fresa",
        precio: 35
      },
      {
        variante: "Combinado",
        precio: 40
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/malteada.png?updatedAt=1693950153744"
  },
  {
    platillo: "Bebidas",
    tipos: [
      {
        variante: "Café Negro o Té",
        precio: 18
      },
      {
        variante: "Café Con Leche",
        precio: 23
      },
      {
        variante: "Yogurt para Beber",
        precio: 15
      },
      {
        variante: "Refresco en Botella 500mls",
        precio: 15
      },
      {
        variante: "Boing 500mls",
        precio: 18
      },
      {
        variante: "Vaso de Agua de Sabor",
        precio: 10
      },
      {
        variante: "Agua Natural 500mls",
        precio: 9
      },
      {
        variante: "Agua Natural 1000mls",
        precio: 18
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/bebidas.jpg?updatedAt=1693950152112"
  },
  {
    platillo: "Dulcería",
    tipos: [
      {
        variante: "Cacahuate Japónes Karate",
        precio: 16
      },
      {
        variante: "Gomitas con chile",
        precio: 12
      },
      {
        variante: "Papas Sabritas",
        precio: 16
      },
      {
        variante: "Paletas pelon, vero mix",
        precio: 5
      },
      {
        variante: "Chicklets Bubbaloo",
        precio: 3
      },
      {
        variante: "Chocolates Hershey's, MilkyWay",
        precio: 20
      },
      {
        variante: "Chiclets orbit o trident",
        precio: 5
      },
      {
        variante: "Rocaleta",
        precio: 10
      },
      {
        variante: "Barra Milch",
        precio: 16
      },
      {
        variante: "Bolzaza",
        precio: 24
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/dulces.jpg?updatedAt=1693950152179"
  },
  {
    platillo: "Fruta de la Estación",
    tipos: [
      {
        variante: "1 Orden",
        precio: 30
      }
    ],
    imagen:
      "https://ik.imagekit.io/glinares/hispana_menu/fruit.png?updatedAt=1693950152872"
  }
];

export const paquetes = [
  {
    platillo: "#1",
    tipos: [
      {
        variante:
          "Café o Té + Fruta + huevo al gusto acompañado de frijoles refritos y tortillas(jamon, salchicha, a la mexicana, en salsa, con chorizo)",
        precio: "$55",
        variantes: [
          "Café o Té",
          "Fruta",
          "Huevo al gusto acompañado de frijoles refritos y tortillas (jamón, salchicha, a la mexicana, en salsa, con chorizo)"
        ]
      }
    ]
  },
  {
    platillo: "#2",
    tipos: [
      {
        variante: "Café o Té + Fruta + Enchiladas con huevo (3pz)",
        precio: "$50",
        variantes: ["Café o Té", "Fruta", "Enchiladas con huevo (3pz)"]
      }
    ]
  },
  {
    platillo: "#3",
    tipos: [
      {
        variante: "Café o Té + Fruta + Chilaquiles verdes o rojos con pollo ",
        precio: "$55",
        variantes: [
          "Café o Té",
          "Fruta",
          "Chilaquiles verdes o rojos con pollo"
        ]
      }
    ]
  },
  {
    platillo: "#4",
    tipos: [
      {
        variante: "Café o Té + 2 Wafles con mantequilla, y mermelada o miel",
        precio: "$30",
        variantes: ["Café o Té", "2 Wafles con mantequilla, y mermelada o miel"]
      }
    ]
  },
  {
    platillo: "#5",
    tipos: [
      {
        variante:
          "Café o Té + Fruta + Orden de enfrijoladas (3 piezas) con huevo",
        precio: "Con Huevo $50 / Con Pollo $55",
        variantes: ["Café o Té", "Fruta", "Orden de enfrijoladas (3 piezas)"]
      }
    ]
  }
];
