import Container from "./Container";

const PaqueteDescription = ({ variantes, precio }) => {
  return (
    <>
      <ul className="space-y-1 list-disc list-inside">
        {variantes.map((item, idx) => (
          <li key={idx}> {item}</li>
        ))}
      </ul>
      <p className="mt-3 text-md text-center font-medium text-gray-900">
        {precio}
      </p>
    </>
  );
};

const PaqueteCard = ({ platillo, tipos }) => {
  return (
    <div className="group border-gray-100 border rounded-md p-6">
      <h3 className="text-xl font-bold text-center">{platillo}</h3>
      {tipos.map((descrition, idx) => {
        return (
          <PaqueteDescription
            precio={descrition.precio}
            variantes={descrition.variantes}
            key={idx}
          />
        );
      })}
    </div>
  );
};

const Paquetes = ({ paquetes }) => {
  return (
    <Container title="Paquetes">
      <div className="grid grid-cols-2 gap-y-10 gap-x-5 sm:grid-cols-2 lg:grid-cols-3 ">
        {paquetes.map((item, idx) => {
          return (
            <PaqueteCard
              platillo={item.platillo}
              tipos={item.tipos}
              key={idx}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default Paquetes;
